<template>
  <div>
    <b-row>
      <b-col xl="2" md="4" sm="6">
        <b-link @click="uploadFile">
          <b-card class="text-center">
            <b-avatar class="mb-1" variant="light-info" size="45">
              <feather-icon size="21" icon="FileIcon" />
            </b-avatar>
            <div class="truncate">
              <h4 class="mb-25 font-weight-bolder">Upload CNIC List</h4>
            </div>
          </b-card>
        </b-link>
      </b-col>
    </b-row>
    <b-row>
      <b-card>
        <h3 class="mb-2">History</h3>
        <b-table
          responsive="sm"
          :fields="fields"
          :items="logs"
          details-td-class="m-0 p-0"
          :tbody-tr-class="rowClass"
          sticky-header="700px"
          small
          class="text-black"
        >
        </b-table>
      </b-card>
    </b-row>
    <file-upload-modal
      @modalClosed="onModalClosedUpload"
      :key="`upload-${fileuploadModalCount}`"
    />
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import FileUploadModal from "@/components/admin/FileUploadModal.vue";
import util from "@/util.js";

export default {
  components: {
    FileUploadModal,
  },
  data() {
    return {
      fileuploadModalCount: 0,
      fields: [
        { key: "uploaded_on", label: "Uploaded On", sortable: true },
        { key: "uploaded_by_data.name", label: "Uploaded By", sortable: true },
      ],
      logs: null,
    };
  },
  mixins: [util],

  async mounted() {
    await this.fetchData();
  },
  methods: {
    ...mapActions({
      fetchCnicLogs: "appData/fetchCnicLogs",
    }),
    async fetchData() {
      this.show = true;
      try {
        const res = await this.fetchCnicLogs();
        if (res.status == 200) {
          this.logs = res.data
        }
      } catch (error) {
        this.show = false;
        this.displayError(error);
      }
    },
    async uploadFile() {
      this.fileuploadModalCount += 1;
      this.$nextTick(() => {
        this.$bvModal.show("file-upload-modal");
      });
    },
    async onModalClosedUpload() {
      this.$bvModal.hide("file-upload-modal");
      await this.fetchData();
    },
  },
  computed: {
    ...mapGetters({
      hasPermission: "appData/hasPermission",
    }),
  },
};
</script>

<style></style>
